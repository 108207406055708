@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap');

:root {
  --bg-main: #063252;
}

html, body {
    height: 100%;
}


body {
  background-color: #063252;
  font-family: 'Nunito Sans', sans-serif;
  color: white;
}

.bg-header {
  position: absolute;
  width: 100%;
  height: 948px;
  background: var(--bg-main) url('../public/images/bg_header2.webp');
  background-repeat: repeat-x;
  background-position: center;
}

.bg-footer-wrapper {
  position: relative;
}

.bg-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 948px;
  background: var(--bg-main) url('../public/images/bg_footer2.webp');
  background-repeat: repeat-x;
  background-position: center;
}

.bg-overlay-linear {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0.1), 
    rgba(0, 0, 0, 0.7)
  );
  z-index: 1;
}

.bg-overlay-radial {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    rgba(0, 0, 0, 0.0) 80%, 
    rgba(0, 0, 0, 0.3)
  );
  z-index: 1;
}

.content {
  position: relative;
  z-index: 1;
}